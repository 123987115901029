import axios from "axios";

const SET_LIST = "SET_LIST";
const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
const IMPORTANT_EQUIPMENT_LIMIT = 10;

export default {
    state: {
        list: []
    },
    getters: {
        stockSelectedEquipmentCount: state => {
            let countStockSelectedEquipmentCount = 0;

            for (const [group, equipment] of Object.entries(
                state.list
            )) {
                equipment.forEach(x => {
                    if (!x.isDisabled && x.isSelected) {
                        countStockSelectedEquipmentCount++;
                    }
                });
            }

            return countStockSelectedEquipmentCount;
        },
        stockSelectedImportantCount: state => {
            let countStockSelectedImportantCount = 0;

            for (const [group, equipment] of Object.entries(
                state.list
            )) {
                equipment.forEach(x => {
                    if (!x.isDisabled && (x.isImportant && x.isSelected || x.isSelectedInTrim && x.isImportant)) {
                        countStockSelectedImportantCount++;
                    }
                });
            }

            return countStockSelectedImportantCount;
        },
        selectedEquipmentCount: state => {
            let countSelectedEquipmentCount = 0;

            for (const [group, equipment] of Object.entries(
                state.list
            )) {
                equipment.forEach(x => {
                    if (!x.isDisabled && (x.isSelected || x.isSelectedInTrim)) {
                        countSelectedEquipmentCount++;
                    }
                });
            }

            return countSelectedEquipmentCount;
        },
        maxLimit: state => {
            return IMPORTANT_EQUIPMENT_LIMIT;
        },
    },
    mutations: {
        [SET_LIST](state, list) {
            state.list = list;
        },
        [UPDATE_EQUIPMENT](state, payload) {
            state.list[payload.group].splice(
                payload.index,
                1,
                payload.equipment
            );
        }
    },
    actions: {
        setList({ commit }, list) {
            commit(SET_LIST, list);
        },
        update({ commit, state }, payload) {
            const index = state.list[payload.group].findIndex(
                x => x.id === payload.equipment.id
            );

            if (index !== -1) {
                commit(UPDATE_EQUIPMENT, {
                    index: index,
                    group: payload.group,
                    equipment: payload.equipment
                });
            }
        },
        persist({ commit, state }, stockId) {
            let updatedEquipments = [];

            for (let [group, equipments] of Object.entries(state.list)) {
                equipments.forEach(equipment => {
                    let needUpdate = false;

                    if (equipment.isSelectedInTrim && equipment.isDisabled) {
                        needUpdate = true;
                    } else if (
                        equipment.isSelectedInTrim &&
                        equipment.overrideImportant
                    ) {
                        needUpdate = true;
                    } else {
                        needUpdate = equipment.isSelected;
                    }

                    if (needUpdate) {
                        updatedEquipments.push({
                            id: equipment.id,
                            isImportant: equipment.isDisabled ? false : equipment.isImportant,
                            isDisabled: equipment.isDisabled
                        });
                    }
                });
            }

            return axios.put(route("stock.equipment.update", { id: stockId }), {
                equipments: updatedEquipments
            });
        }
    }
};
