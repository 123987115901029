import { usePage } from '@inertiajs/vue3'

const page = usePage()

export default {
    methods: {
       __(key, replace = {}) {
            const keys = key.split('.');
            let translation = page.props.language;
            // let translation = document.querySelector('html').getAttribute('lang')
            keys.forEach(function(keyTmp){
                 translation = translation[keyTmp]
                    ? translation[keyTmp]
                    : keyTmp
            });
            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });
            return translation
        },
        __n(key, number, replace = {}) {
            let options = key.split('|');
            key = options[1];
            if(number == 1) {
                key = options[0];
            }
            return __n(key, replace);
        },
    },
}
