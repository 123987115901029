import { Livewire, Alpine } from '~vendor/livewire/livewire/dist/livewire.esm';



Alpine.directive('clipboard', (el, { value, expression }) => {
    el.addEventListener('click', () => {
        navigator.clipboard.writeText(expression)
    })
})


Alpine.directive('tooltip', (el, { value, modifiers, expression }, { cleanup }) => {
    if (!expression) {
        return;
    }
    let tooltipText = expression;
    let tooltipArrow = !modifiers.includes('noarrow');
    let tooltipTheme = value ?? '';
    let tooltipPosition = 'top';
    let tooltipId = 'alpine-tooltip-' + Date.now().toString(36) + Math.random().toString(36).substring(2, 7);
    let positions = ['top', 'bottom', 'left', 'right'];
    let elementPosition = getComputedStyle(el).position;

    for (let position of positions) {
        let newPosition = value ? position + ':' + value : position
        if (modifiers.includes(newPosition)) {
            tooltipPosition = position;
            break;
        }
    }

    if (!['relative', 'absolute', 'fixed'].includes(elementPosition)) {
        el.style.position = 'relative';
    }

    let tooltipHTML = `
        <div id="${tooltipId}"  x-data="{ tooltipVisible: false, tooltipText: '${tooltipText}', tooltipArrow: ${tooltipArrow}, tooltipPosition: '${tooltipPosition}' }" x-ref="tooltip" x-init="setTimeout(function(){ tooltipVisible = true; }, 1);" x-show="tooltipVisible" :class="{'alpine-tooltip--top': tooltipPosition == 'top', 'alpine-tooltip--left': tooltipPosition == 'left', 'alpine-tooltip--bottom': tooltipPosition == 'bottom', 'alpine-tooltip--right': tooltipPosition == 'right'}" class="alpine-tooltip alpine-tooltip--${tooltipTheme}" x-cloak>
            <div x-show="tooltipVisible"
                    x-transition:enter.duration.500ms
                    x-transition:enter-start.opacity.0.scale.90
                    x-transition:enter-end.opacity.100.scale.100
                    x-transition:leave-start.opacity.100.scale.100
                    x-transition:leave-end.opacity.0.scale.90
                    class="alpine-tooltip__inner">
                <p x-html="tooltipText" class="alpine-tooltip-inner__text"></p>
                <div x-ref="tooltipArrow" x-show="tooltipArrow" :class="{'alpine-tooltip-inner__arrow--top': tooltipPosition == 'top', 'alpine-tooltip-inner__arrow--left': tooltipPosition == 'left', 'alpine-tooltip-inner__arrow--bottom': tooltipPosition == 'bottom', 'alpine-tooltip-inner__arrow--right': tooltipPosition == 'right'}" class="alpine-tooltip-inner__arrow">
                    <div :class="{'alpine-tooltip-arrow--top': tooltipPosition == 'top', 'alpine-tooltip-arrow--left': tooltipPosition == 'left', 'alpine-tooltip-arrow--bottom': tooltipPosition == 'bottom', 'alpine-tooltip-arrow--right': tooltipPosition == 'right'}" class="alpine-tooltip-arrow"></div>
                </div>
            </div>
        </div>
    `;

    el.dataset.tooltip = tooltipId;

    let mouseEnter = function (event) {
        if (!document.getElementById(tooltipId)) {
            el.innerHTML += tooltipHTML;
        }
    };

    let mouseLeave = function (event) {
        event.target.querySelector("[id^='alpine-tooltip-']").remove();
    };

    el.addEventListener('mouseenter', mouseEnter);
    el.addEventListener('mouseleave', mouseLeave);

    cleanup(() => {
        el.removeEventListener('mouseenter', mouseEnter);
        el.removeEventListener('mouseleave', mouseLeave);
    });
});


Livewire.start()
