import Choices from 'choices.js'

window.Choices = Choices
window.initChoicesJs = initChoicesJs

jQuery(window).on('load', function () {
  adjustPageHeader()
  initTooltip()
  formatCurrency()
  initPhoneInputsFormat()
  initRemainingCharacters()
  initChoicesJs()
  handleDataTablesLoading()
})

jQuery(window).on('shown.bs.modal', function () {
  initTooltip()
})

jQuery(document).on('click', "a[data-widget='pushmenu']", function () {
  adjustSidebarCollapsed()
})

function initRemainingCharacters() {
  jQuery('.has-limit-characters').on('input', function () {
    updateCharactersRemaining(this)
  })
}

function updateCharactersRemaining(input) {
  const maxLength = jQuery(input).attr('maxlength')
  const currentLength = jQuery(input).val().length
  const remainingLength = maxLength - currentLength
  jQuery(input).next('p.remaining-characters').remove()
  jQuery('.invalid-feedback').next('p.remaining-characters').remove()
  jQuery(input).after(
    "<p class='remaining-characters'>" +
      remainingLength +
      ' ' +
      remainingCaracters.get('remainingCaracters') +
      '</p>'
  )
}

function initPhoneInputsFormat() {
  jQuery('.us_ca_phone').on('input', function (e) {
    let x = jQuery(this)
      .val()
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  })
}

function initTooltip() {
  ;[].slice
    .call(document.querySelectorAll('[data-toggle="tooltip"]'))
    ?.map(function (tooltipTriggerEl) {
      return new window.Bootstrap.Tooltip(tooltipTriggerEl)
    })
}

function adjustPageHeader() {
  if (!jQuery('.page-header__options > div').hasClass('button-add-wrapper')) {
    jQuery('.page-header').css({
      'padding-top': '12px',
      'padding-bottom': '12px'
    })
  }
  if (
    jQuery('.button-add-wrapper > a').hasClass('button-refresh') ||
    jQuery('.button-add-wrapper > a').hasClass('button-back')
  ) {
    jQuery('.page-header').css({
      'padding-top': '5px',
      'padding-bottom': '5px'
    })
  }
}

function adjustSidebarCollapsed() {
  if (jQuery('body').hasClass('sidebar-collapse')) {
    jQuery('.nav-header ~ .nav-item:not(.has-treeview) > .nav-link').css('padding', '0.5rem 1rem')
  } else {
    jQuery('.nav-header ~ .nav-item:not(.has-treeview) > .nav-link').css('padding-left', '1.5rem')
  }
}

// From StackOverflow source code
String.prototype.formatUnicorn =
  String.prototype.formatUnicorn ||
  function () {
    'use strict'
    var str = this.toString()
    if (arguments.length) {
      var t = typeof arguments[0]
      var key
      var args =
        'string' === t || 'number' === t ? Array.prototype.slice.call(arguments) : arguments[0]

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key])
      }
    }

    return str
  }

function formatCurrency() {
  let currencyClasses = document.getElementsByClassName('currency-value')

  if (currencyClasses) {
    for (let element of currencyClasses) {
      if (isNumericValue(element.innerText)) {
        let newValue = formatValue(parseFloat(element.innerText))
        element.innerText = newValue
      }
    }
  }
}

function formatValue(value) {
  if (value) {
    return value.toLocaleString('fr-CA', 'CAD') + ' jQuery'
  }
}

function isNumericValue(value) {
  return value && !isNaN(parseFloat(value))
}

function initChoicesJs() {
  let options =
    typeof jsChoicesOptions === 'undefined'
      ? new Map([['addItemText', '', 'maxItemText', '']])
      : jsChoicesOptions

  jQuery('.js-choice').each(function () {
    new Choices(this, {
      loadingText: options.get('loadingText'),
      noResultsText: options.get('noResultsText'),
      noChoicesText: options.get('noChoicesText'),
      itemSelectText: options.get('itemSelectText'),
      searchResultLimit: 100,
      removeItemButton: jQuery(this).attr('multiple'),
      addItemText: value => {
        return options.get('addItemText').formatUnicorn({
          value: value
        })
      },
      maxItemText: maxItemCount => {
        return options.get('maxItemText').formatUnicorn({
          maxItemCount: maxItemCount
        })
      }
    })
  })
  jQuery('.js-choice--unsorted').each(function () {
    new Choices(this, {
      shouldSort: false,
      loadingText: options.get('loadingText'),
      noResultsText: options.get('noResultsText'),
      noChoicesText: options.get('noChoicesText'),
      itemSelectText: options.get('itemSelectText'),
      searchResultLimit: 100,
      removeItemButton: jQuery(this).attr('multiple'),
      addItemText: value => {
        return options.get('addItemText').formatUnicorn({
          value: value
        })
      },
      maxItemText: maxItemCount => {
        return options.get('maxItemText').formatUnicorn({
          maxItemCount: maxItemCount
        })
      }
    })
  })
}

function handleDataTablesLoading() {
  const tableObserver = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      const processing = mutation.target
      const dataTable = document.getElementById('dataTableBuilder_wrapper')
      if (processing && processing.style.display !== 'none') {
        dataTable.classList.add('processing')
      } else {
        dataTable.classList.remove('processing')
      }
    })
  })

  const targetNode = document.body
  const config = { attributes: false, childList: true, subtree: true }

  const domObserver = new MutationObserver(function (mutationsList, domObserver) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        const dataTable = document.getElementById('dataTableBuilder_wrapper')
        if (dataTable) {
          domObserver.disconnect()
          const processing = dataTable.querySelector('.dataTables_processing')

          tableObserver.observe(processing, {
            attributes: true,
            attributeFilter: ['style']
          })
        }
      }
    }
  })

  domObserver.observe(targetNode, config)
}
