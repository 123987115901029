import axios from "axios";

const SET_LIST = "SET_LIST";
const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
const IMPORTANT_EQUIPMENT_LIMIT = 10;

export default {
    state: {
        list: []
    },
    getters: {
        selectedImportantCount: state => {
            let countSelectedImportantCount = 0;

            for (const [group, equipment] of Object.entries(state.list)) {
                equipment.forEach(x => {
                    if (!x.isDisabled && x.isImportant && x.isSelected) {
                        countSelectedImportantCount++;
                    }
                });
            }

            return countSelectedImportantCount;
        },
        selectedEquipmentCount: state => {
            let countSelectedEquipmentCount = 0;

            for (const [group, equipment] of Object.entries(state.list)) {
                equipment.forEach(x => {
                    if (!x.isDisabled && x.isSelected) {
                        countSelectedEquipmentCount++;
                    }
                });
            }

            return countSelectedEquipmentCount;
        },
        maxLimit: state => {
            return IMPORTANT_EQUIPMENT_LIMIT;
        }
    },
    mutations: {
        [SET_LIST](state, list) {
            state.list = list;
        },
        [UPDATE_EQUIPMENT](state, payload) {
            state.list[payload.group].splice(
                payload.index,
                1,
                payload.equipment
            );
        }
    },
    actions: {
        setList({ commit }, list) {
            commit(SET_LIST, list);
        },
        unselectAll({ commit, state }) {
            let newList = {};
            Object.keys(state.list).forEach(group => {
                newList[group] = [];
                state.list[group].forEach(equipment => {
                    let unselectedEquipment = Object.assign(
                        {
                            isSelected: false,
                            isImportant: false
                        },
                        {
                            id: equipment.id,
                            name: equipment.name
                        }
                    );
                    newList[group].push(unselectedEquipment);
                });
            });
            commit(SET_LIST, newList);
        },
        update({ commit, state }, payload) {
            const index = state.list[payload.group].findIndex(
                x => x.id === payload.equipment.id
            );

            if (index !== -1) {
                commit(UPDATE_EQUIPMENT, {
                    index: index,
                    group: payload.group,
                    equipment: payload.equipment
                });
            }
        },
        persist({ state }, trimId) {
            let updatedEquipments = [];

            for (let [group, equipments] of Object.entries(state.list)) {
                equipments.forEach(equipment => {
                    let needUpdate = false;

                    needUpdate = equipment.isSelected;

                    if (needUpdate) {
                        updatedEquipments.push({
                            id: equipment.id,
                            isImportant: equipment.isDisabled
                                ? false
                                : equipment.isImportant,
                            isDisabled: equipment.isDisabled
                        });
                    }
                });
            }
            return axios.put(route("trims.equipment.update", { id: trimId }), {
                equipments: updatedEquipments
            });
        }
    }
};
