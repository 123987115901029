import { App } from 'vue'
import { usePage } from '@inertiajs/vue3'
interface PermissionsProps {
  permissions: Array<string>
  roles: Array<string>
}
export default {
  install(app: App) {
    app.config.globalProperties.$can = function (value: string): boolean {
      if (!usePage().props.hasOwnProperty('permissions')) {
        console.warn('inertia response has no permissions props')
        return false
      }
      const permissionsProps = usePage().props.permissions as PermissionsProps

      if (!permissionsProps.hasOwnProperty('permissions')) {
        console.warn('inertia response has permissions props has no permission key')

        return false
      }

      const permissions = permissionsProps.permissions
      let _return: boolean = false

      if (!Array.isArray(permissions)) {
        return false
      }

      if (value.includes('|')) {
        value.split('|').forEach((item: string) => {
          if (permissions.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach((item: string) => {
          if (!permissions.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = permissions.includes(value.trim())
      }

      return _return
    }

    app.config.globalProperties.$is = function (value: string): boolean {
      if (!usePage().props.hasOwnProperty('roles')) {
        console.warn('inertia response has permissions props')
        return false
      }
      const permissionsProps = usePage().props.roles as PermissionsProps

      if (!permissionsProps.hasOwnProperty('roles')) {
        console.warn('inertia response has permissions props has no role key')
        return false
      }

      const roles = permissionsProps.roles
      let _return: boolean = false

      if (!Array.isArray(roles)) {
        return false
      }

      if (value.includes('|')) {
        value.split('|').forEach((item: string) => {
          if (roles.includes(item.trim())) {
            _return = true
          }
        })
      } else if (value.includes('&')) {
        _return = true
        value.split('&').forEach((item: string) => {
          if (!roles.includes(item.trim())) {
            _return = false
          }
        })
      } else {
        _return = roles.includes(value.trim())
      }

      return _return
    }
  }
}
