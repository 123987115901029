$(window).on("load", function () {
    triggerSimpleBadgeUpdater();
});

function triggerSimpleBadgeUpdater() {
    $(document).on("submit", ".badge-form-updater", function (event) {
        event.preventDefault();
        $(".fa-triangle-exclamation").remove();
        let $form = $(this);
        let formData = $form.serializeArray();
        const routeObject = formData.find((obj) => obj.name === "route");
        const checkMarkObject = formData.find(
            (obj) => obj.name === "is_check_mark"
        );
        const route = routeObject ? routeObject.value : null;
        const isCheckMark = checkMarkObject ? checkMarkObject.value : null;
        let badgeColumn = $form.find(".badge-column");
        let badgeColumnValue = badgeColumn.html();
        $.ajax({
            type: "POST",
            url: route,
            data: formData,
            beforeSend: function () {
                let spinner =
                    '<span class="spinner-border spinner-border-sm " role="status" aria-hidden="true" style="    vertical-align: baseline;height: 0.5rem;width: 0.5rem;" ></span>';
                if (isCheckMark) {
                    badgeColumn.hide();
                    badgeColumn.after(spinner);
                } else {
                    badgeColumn.html(spinner);
                }
            },
            success: function (response) {
                $("#" + response.data.badgeSelector).empty();
                $("#" + response.data.badgeSelector).append(
                    response.data.badgeElement
                );
            },
            error: function (err) {
                $(".spinner-border").remove();
                badgeColumn.html(badgeColumnValue);
                let errEl =
                    '<i class="fa-solid fa-triangle-exclamation text-danger" style="vertical-align: baseline;"></i>';

                badgeColumn.after(errEl).end();
            },
        });
    });
}
