import camelCase from "lodash/camelCase";
// Storing in variable a context with all files in this folder
// ending with `.js`.
const modulesFiles = import.meta.glob("./*.js",{ eager: true })
const modules = {};

Object.keys(modulesFiles).forEach(fileName => {
    if (fileName === './index.js') return
    // filter fullstops and extension
  // and return a camel-case name for the file
    const moduleName = camelCase(
        fileName.replace(/(\.\/|\.js)/g, '')
    )
  // create a dynamic object with all modules
    modules[moduleName] = {
    // add namespace here
        namespaced: true,
        ...(modulesFiles[fileName].default || modulesFiles[filename])
    // if you have exported the object with name in the module `js` file
    // e.g., export const name = {};
    // uncomment this line and comment the above
        // ...modulesFiles(fileName)[moduleName]
    }
})

export default modules;
