import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    // BubbleController,
    DoughnutController,
    LineController,
    PieController,
    // PolarAreaController,
    // RadarController,
    // ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    // RadialLinearScale,
    // TimeScale,
    // TimeSeriesScale,
    // Decimation,
    // Filler,
    Legend,
    // Title,
    Tooltip,
    // SubTitle
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    // BubbleController,
    DoughnutController,
    LineController,
    PieController,
    // PolarAreaController,
    // RadarController,
    // ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    // RadialLinearScale,
    // TimeScale,
    // TimeSeriesScale,
    // Decimation,
    // Filler,
    Legend,
    // Title,
    Tooltip,
    // SubTitle
);

window.Chart = Chart
window.ChartColors = ['#00a1e0', '#cd0e2e', '#4e9a60', '#ffc107', '#6c757d', '#343a40', '#F86D23'];



