import axios from "axios";

const ADD_MEDIA = "ADD_MEDIA";
const LOAD_MEDIA = "LOAD_MEDIA";
const UPDATE_MEDIA = "UPDATE_MEDIA";
const REMOVE_MEDIA = "REMOVE_MEDIA";

export default {
    state: {
        media: []
    },
    getters: {},
    mutations: {
        [LOAD_MEDIA](state, mediaList) {
            state.media = mediaList;
        },
        [ADD_MEDIA](state, media) {
            state.media.push(media);
        },
        [UPDATE_MEDIA](state, payload) {
            state.media.splice(payload.index, 1, payload.media);
        },
        [REMOVE_MEDIA](state, mediaId) {
            let index = state.media.findIndex(x => x.id === mediaId);
            if (index !== -1) {
                state.media.splice(index, 1);
            }
        }
    },
    actions: {
        loadMedia({ commit }, mediaUrl) {
            return axios.get(mediaUrl, {headers: {"Accept": "application/json"}}).then(response => {
                let mediaList = [];
                response.data.forEach((x) => {
                    mediaList.push({
                        file: x.url,
                        id: x.id,
                        active: false,
                        name: x.name,
                        size: x.size,
                        type: x.type,
                        fileObject: true,
                        error: "",
                        success: true,
                        postAction: mediaUrl + x.id,
                        progress: "0.00",
                        thumb: x.url,
                        order: x.order,
                    });
                    mediaList.sort(function (a, b) {
                        return parseInt(a.order) - parseInt(b.order);
                    });
                    commit(LOAD_MEDIA, mediaList);
                });
            });
        },
        addOrUpdateMedia({ state, commit }, media) {
            let index = state.media.findIndex(x => x.id === media.id);
            if (index === -1) {
                axios
                    .get(media.url, {
                        responseType: "arraybuffer",
                        timeout: 30000
                    })
                    .then(response => {
                        let blob = new Blob([response.data]);
                        const url = window.URL.createObjectURL(blob);
                        commit(LOAD_MEDIA, [{
                            file: media,
                            id: media.id,
                            active: false,
                            name: media.name,
                            size: media.size,
                            type: media.type,
                            fileObject: true,
                            error: "",
                            success: true,
                            postAction: media.url + media.id,
                            progress: "0.00",
                            blob: url,
                            thumb: url,
                            order: media.order
                        }]);
                    })
            } else {
                commit(UPDATE_MEDIA, {
                    index: index,
                    media: media
                });
            }
        },
        updateMediaList({ commit }, payload) {
            const order = payload.list.map(newOrder => newOrder.id);
            const url = payload.url;

            return axios.put(url + "/order", order).then(() => {
                let orderedList = payload.list.sort(function (item, index) {
                    item.order > index ? 1 : -1;
                });
                commit(LOAD_MEDIA, orderedList);
            });
        },
        deleteMedia({ state, commit }, media) {
            return axios.delete(media.url).then(() => {
                commit(REMOVE_MEDIA, media.id);
            });
        },
        removeMediaFromStore({ commit }, media) {
            commit(REMOVE_MEDIA, media.id);
        },
        attachMediaFromTrim({ }, mediaUrl) {
            return axios.put(mediaUrl + "attach");
        }
    }
};
